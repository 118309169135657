import React from 'react';
import { isEmpty } from 'lodash';
import { trackEvents } from "@myaccount/helper";
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import FormControl from '@mui/material/FormControl';
import TextField from "@mui/material/TextField";
import InputAdornment from '@mui/material/InputAdornment';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import LinkMui from '@mui/material/Link';
import { MYACCOUNT_LIGHT } from "@myaccountUrls";

// Icons
import Facebook from '@mui/icons-material/Facebook';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import EmailIcon from '@mui/icons-material/Email';
import UserIcon from '@mui/icons-material/AccountCircle';
import Google from './google.inline.svg';
import Alert from '@myaccount/alert';

const TermsText = ({ dialogTermsProps, termsText, termsLink, termsLabel, privacyLink, privacyLabel, termsLinkProps }) => {
    return(
        <Typography classes={{root: "dialogTerms"}} {...dialogTermsProps}>
            {termsText} <LinkMui href={termsLink} {...termsLinkProps}>{termsLabel}</LinkMui>
            {privacyLink && <> and <LinkMui href={privacyLink} {...termsLinkProps}>{privacyLabel}</LinkMui></>}.
        </Typography>
    )
}

const StyledDialogContent = (props) => {
    const {
        // handleClose,
        subtitle,
        showSocialLogin,
        showTerms,
        termsText,
        termsLinkProps,
        termsLink,
        termsLabel,
        privacyLink,
        privacyLabel,
        state,
        services,
        formActionLabel,
        dialogContentProps
    } = props

    const {
        dialogContentTitleProps,
        dialogSocialButtonProps,
        dialogSocialButtonGoogleProps,
        dialogSocialButtonFBProps,
        dialogSeparatorProps,
        dialogSeparatorSpanProps,
        dialogFormControlProps,
        dialogTermsProps,
        // dialogHelperTextProps,
        dialogActionsBtnProps,

    } = dialogContentProps

    // password field logic
    const [values, setValues] = React.useState({
        showPassword: false,
        newsAndUpdatesSubscription: false
    });
    const [forenameErroMsg, setForenameErroMsg] = React.useState('');
    const [surnameErroMsg, setSurnameErroMsg] = React.useState('');
    const [validation, setValidation] = React.useState({
        forename: false,
        surname: false,
        email: false,
        password: false
    });

    const handleChange = (prop) => (event) => {
        console.log(event.target.checked)
        if (!event.target.value)
            setValidation({...validation, [prop]:true})
        else
            setValidation({...validation, [prop]:false})

        let newVal = event.target.value;

        if (event.target.name === 'newsAndUpdatesSubscription') {
            newVal = event.target.checked
        }

        setValues({ ...values, [prop]: newVal });

        if(prop === 'forename'){
            if(event.target.value?.length > 11){
                setForenameErroMsg("Please enter 12 characters only")
                setValidation({...validation, [prop]:true})
            }else{
                setForenameErroMsg("")
            }
        }
        if(prop === 'surname'){
            if(event.target.value?.length > 29){
                setSurnameErroMsg("Please enter 30 characters only")
                setValidation({...validation, [prop]:true})
            }else{
                setSurnameErroMsg("")
            }
        }
    };
    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        let hasError = false;
        // if(values.forename.length < 12 && values.surname.length < 30){
            if (isEmpty(values)) {
                setValidation({
                    forename: false,
                    surname: false,
                    email: true,
                    password: true
                })
            } else {
                let validationAlt = {}
                let errotStatus;
                Object.entries(validation).map(([key, value]) => {
                    if (!values[key]) {
                        errotStatus = true;
                        hasError = true;
                    } else {
                        errotStatus = false;
                    }
                    validationAlt[key] = errotStatus;
                })
                setValidation(validationAlt)
            }

            if (!hasError)
                services.registerWithEmail({...values});
            else
                services.updateState({message: '', error: false});
        // }
    };

    // Call social login
    const doSocialLogin = ( provider, eventId ) => {
        trackEvents({
          event: 'buttonClick',
          eventId: eventId
        })
        services.providerLogin(provider);
    }

    return(
        <DialogContent classes={{root: "dialogContent"}}>
            <DialogContentText classes={{root: "dialogContentTitle"}} {...dialogContentTitleProps}>
                {subtitle}
            </DialogContentText>
            {showSocialLogin &&
                <Box className={"dialogSocialButtonCont"}>
                    <Button
                        {...dialogSocialButtonProps}
                        classes={{root: "dialogSocialButton"}}
                        onClick={ () => {doSocialLogin('google', 'myAccountSignupGoogle')}}
                        id="myAccountSignupGoogle"
                    >
                        <Google {...dialogSocialButtonGoogleProps} />
                    </Button>
                    <Button
                        {...dialogSocialButtonProps}
                        classes={{root: "dialogSocialButton"}}
                        onClick={ () => {doSocialLogin('facebook', 'myAccountSignupFacebook')}}
                        id="myAccountSignupFacebook"
                    >
                        <Facebook {...dialogSocialButtonFBProps} />
                    </Button>
                </Box>
            }

            <Typography
                {...dialogSeparatorProps}
                classes={{root: "dialogSeparator"}}
            >
                <Typography classes={{root: "dialogSeparatorSpan"}} {...dialogSeparatorSpanProps}>or sign up with email</Typography>
            </Typography>

            <form noValidate autoComplete="off" id="myAccountSignUpForm">
                <Alert type="alert" message={state.message} severity={state.error ? 'error' : 'success'} />
                <FormControl {...dialogFormControlProps}>
                    <TextField
                        id="signup-forename"
                        type="text"
                        label="First name"
                        onChange={handleChange('forename')}
                        classes={{root: "dialogFormControlElem"}}
                        required={true}
                        error={validation['forename']}
                        helperText={forenameErroMsg}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <UserIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormControl>
                <FormControl {...dialogFormControlProps}>
                    <TextField
                        id="signup-surname"
                        type="text"
                        label="Surname"
                        onChange={handleChange('surname')}
                        classes={{root: "dialogFormControlElem"}}
                        required={true}
                        error={validation['surname']}
                        helperText={surnameErroMsg}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <UserIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormControl>
                <FormControl {...dialogFormControlProps}>
                    <TextField
                        id="signup-email"
                        type="text"
                        label="Email Address"
                        onChange={handleChange('email')}
                        classes={{root: "dialogFormControlElem"}}
                        required={true}
                        error={validation['email']}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <EmailIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormControl>
                <FormControl {...dialogFormControlProps}>
                    <TextField
                        id="signup-password"
                        type={values.showPassword ? 'text' : 'password'}
                        value={values.password}
                        label="Password"
                        onChange={handleChange('password')}
                        classes={{root: "dialogFormControlElem"}}
                        required={true}
                        error={validation['password']}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormControl>

                {!MYACCOUNT_LIGHT && (
                    <FormControl {...dialogFormControlProps}>
                        <FormControlLabel
                            classes={{root: "dialogCheckbox"}}
                            control={
                                <Checkbox
                                    size="small"
                                    checked={values.newsAndUpdatesSubscription}
                                    className={'dialogCheckbox'}
                                    name="newsAndUpdatesSubscription"
                                    onChange={handleChange('newsAndUpdatesSubscription')}
                                />
                            }
                            label="Agree to receive some interesting news and updates"
                        />
                    </FormControl>
                )}

                {showTerms && termsText &&
                    <TermsText
                        dialogTermsProps={dialogTermsProps}
                        termsText={termsText}
                        termsLinkProps={termsLinkProps}
                        termsLink={termsLink}
                        termsLabel={termsLabel}
                        privacyLink={privacyLink}
                        privacyLabel={privacyLabel}
                    />
                }

                <DialogActions classes={{root: "dialogActions"}}>
                    <Button type="submit" disabled={state.loading || false} onClick={handleSubmit} {...dialogActionsBtnProps} classes={{root: "dialogActionsBtn"}} id="myAccountSignupSubmit">
                        {formActionLabel}
                    </Button>
                </DialogActions>
            </form>
        </DialogContent>
    )
};

export default StyledDialogContent;
