import React from 'react';
import {sampleSize } from "lodash";
import { withSubtheme } from '@starberryComponentsMui';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Welcome from '../user/welcome';
import Tabs from '../tabs';
import range from 'lodash/range';
import { MYACCOUNT_MYPROPERTY_URLS } from "@myaccountUrls";
import { setValuesToUrl } from '@myaccount/helper/urls';
import { getPropertyText } from '@myaccount/helper';

import PropertyResultComponent from '@myaccount/property/results';
import { useMyaccountState, useAuthState } from "@myaccountServices"
import RecentActivities from '@myaccount/recentActivities';
import ValuationModule from '@myaccount/simpleModule/valuation';
import ContactModuleComp from "@myaccount/pages/my-property/common/contactModule";
import PendingItems from '@myaccount/pendingItems';
import PendingActions from '@myaccount/pendingActions';
import ModuleRender from "@myaccount/modules/moduleRender";
import DesktopPropertyCardSkeleton
    from "../../../StarberryComponentsMui/components/Skeleton/DesktopPropertyCardSkeleton"
import Stack from "@mui/material/Stack"
import Skeleton from "@mui/material/Skeleton"

const PropertyCards = React.memo((props) => {
    const {
        mypropertiesData,
        loading,
    } = props

    const selling = React.useMemo(() => {
        if (!loading && mypropertiesData.selling) {
            const result = [];
            Object.entries(mypropertiesData.selling).map(([key, value]) => {
                let propertyDetail = value?.property_detail || {}
                if (!value?.property_detail)
                    return;
                let link = setValuesToUrl(MYACCOUNT_MYPROPERTY_URLS, 'selling', propertyDetail?.crm_id)
                result.push({
                    ...propertyDetail,
                    link,
                    offerText: getPropertyText(value)
                });
            });

            return result;
        }

        return [];
    }, [mypropertiesData.selling, loading]);

    const letting = React.useMemo(() => {
        if (!loading && mypropertiesData.letting) {
            const result = [];
            Object.entries(mypropertiesData.letting).map(([key, value]) => {
                let propertyDetail = value?.property_detail || {}
                if (!value?.property_detail)
                    return;
                let link = setValuesToUrl(MYACCOUNT_MYPROPERTY_URLS, 'letting', propertyDetail?.crm_id)
                result.push({
                    ...propertyDetail,
                    link:link,
                    offerText: getPropertyText(value)
                });
            });

            return result;
        }

        return [];
    }, [mypropertiesData.letting, loading]);

    const renting = React.useMemo(() => {
        if (!loading && mypropertiesData.renting) {
            const result = [];
            Object.entries(mypropertiesData.renting).map(([key, value]) => {
                let propertyDetail = value?.property_detail || {}
                if (!value?.property_detail)
                    return;
                let link = setValuesToUrl(MYACCOUNT_MYPROPERTY_URLS, 'renting', propertyDetail?.crm_id)
                result.push({...propertyDetail, link:link});
            });

            return result;
        }

        return [];
    }, [mypropertiesData.renting, loading]);

    const buying = React.useMemo(() => {
        if (!loading && mypropertiesData.buying) {
            const result = [];
            Object.entries(mypropertiesData.buying).map(([key, value]) => {
                let propertyDetail = value?.property_detail || {}
                if (!value?.property_detail)
                    return;
                let link = setValuesToUrl(MYACCOUNT_MYPROPERTY_URLS, 'buying', propertyDetail?.crm_id)
                result.push({...propertyDetail, link:link});
            });

            return result;
        }

        return [];
    }, [mypropertiesData.buying, loading]);

    const [properties, viewMoreLink] = React.useMemo(() => {
        let all = [...selling, ...letting, ...renting, ...buying];

        if (all.length > 3) {
            return [sampleSize(all, 3), 'View All'];
        }

        return [all, ''];
    }, [selling, letting, renting, buying]);

    if (loading) {
        return (
            <Stack direction="column" spacing={2} mb={6}>
                <Stack direction="row" justifyContent="space-between">
                    <Skeleton variant="text" width={100} height={30} />
                    <Skeleton variant="text" width={80} height={30} />
                </Stack>
                <div>
                    <Grid container spacing={4}>
                        {range(0, 2).map(index => (
                            <Grid
                                key={index}
                                item
                                xs={12}
                                sm={6}
                                md={6}
                                lg={4}
                                sx={{ display: { xs: index > 0 ? 'none' : 'block', sm: index > 1 ? 'none' : 'block' }}}
                            >
                                <DesktopPropertyCardSkeleton />
                            </Grid>
                        ))}
                    </Grid>
                </div>
            </Stack>
        )
    }

    return (
        <PropertyResultComponent
            properties={properties}
            title="My Property"
            moreLabel={viewMoreLink}
            moreLinkUrl="/my-property"
            titleLinkMoreTooltipProps={{"title": "View more"}}
        />
    );
})

const actionTabs = [
    {
        "id": "tab1",
        "name": "Pending Actions",
        "content": <PendingActions />,
    },
    {
        "id": "tab2",
        "name": "Recent Activity",
        "content": <RecentActivities />,
    }
]

const ActionComp = React.memo(() => (
    <Tabs
        data={actionTabs}
        fullWidthTabs={true}
    />
));

const MyAccount2Index = withSubtheme((props) => {
    const {
        theme,
        hideValuationModule,
        showContactModule
    } = props;

    const { state, services } = useMyaccountState();
    const { services:authServices } = useAuthState();
    const staticModules = theme.getProp("staticModules");

    React.useEffect(() => {
        services.getMyNegotiatorData()
        services.getMypropertiesData()
        authServices.updateState({pageTitle:`Dashboard`, pageRightCustomComponent: null, pageRightDrawer: null, pageLeftIcon: false});
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const negotiator_info = state?.mynegotiator || {}
    const mypropertiesData = state?.mypropertiesData || {}
    const showValuation = hideValuationModule ? hideValuationModule : false; // isEmpty(mypropertiesData.selling);
    const showContact = showContactModule ? showContactModule : false;

    // if (state.loading) {
    //     return <CircularProgress />
    // }

    return (
        <Container maxWidth="xl">
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Welcome />
                </Grid>
                <Grid item xs={12}>
                    <PropertyCards mypropertiesData={mypropertiesData} loading={state.loading} />
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} lg={8}>
                            <ActionComp />
                            <PendingItems
                                title="Sales Progress"
                                data={props?.sales_progression || []}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={6} md={12}>
                                    {!showValuation && (
                                        <ValuationModule />
                                    )}
                                    {showContact && (
                                        <ContactModuleComp
                                            moduleTitle="Contact your agent"
                                            data={negotiator_info}
                                            />
                                        )
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {staticModules && staticModules.length &&
                    <Grid item xs={12}>
                        <ModuleRender staticModules={staticModules} />
                    </Grid>
                }
            </Grid>
        </Container>
    );
}, 'homePage2')

export default React.memo(MyAccount2Index);
