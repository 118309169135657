export default {
    "props": {
        "btnLabel": "ADD PROOF OF FUNDS",
        "showPlusBtn" : true,
        "btnProps": {
            // "color": "secondary",
            "variant": "contained",
            "sx": (theme) => ({
                "width": "100%",
                "marginBottom": theme.spacing(2),
                [theme.breakpoints.up('md')]: {
                    "maxWidth": "300px",
                    "width": "100%",
                }
            })
        }
    }
}
